import { cn } from "@nextui-org/react";

const Sparkle = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    className={className}
  >
    <path
      d="M128 254l-20.9-70.7c-1-2.6-2.2-5.1-3.5-7.6-1.4-2.5-2.9-4.9-4.6-7.2-1.7-2.3-3.6-4.4-5.6-6.4-2-2-4.2-3.9-6.4-5.6-2.3-1.7-4.7-3.3-7.2-4.6-2.4-1.3-5-2.5-7.6-3.5L2 128l70.7-20.9c2.6-1 5.1-2.2 7.6-3.5 2.5-1.4 4.9-2.9 7.2-4.6 2.3-1.7 4.4-3.6 6.4-5.6 2-2 3.9-4.2 5.6-6.4 1.7-2.3 3.3-4.7 4.6-7.2 1.3-2.4 2.5-5 3.5-7.6L128 2l20.9 70.7c1 2.6 2.2 5.1 3.5 7.6 1.4 2.5 2.9 4.9 4.6 7.2 1.7 2.3 3.6 4.4 5.6 6.4 2 2 4.2 3.9 6.4 5.6 2.3 1.7 4.7 3.3 7.2 4.6 2.4 1.3 5 2.5 7.6 3.5L254 128l-70.7 20.9c-2.6 1-5.1 2.2-7.6 3.5-2.5 1.4-4.9 2.9-7.2 4.6-2.3 1.7-4.4 3.6-6.4 5.6-2 2-3.9 4.2-5.6 6.4-1.7 2.3-3.3 4.7-4.6 7.2-1.3 2.4-2.5 5-3.5 7.6L128 254z"
      fill="#7828c8"
    />
    <path
      d="M128 30l15 50c3 10 8 19 15 27s15 14 25 18l50 15-50 15c-10 3-19 8-27 15s-14 15-18 25l-15 50-15-50c-3-10-8-19-15-27s-15-14-25-18l-50-15 50-15c10-3 19-8 27-15s14-15 18-25l15-50z"
      fill="white"
      opacity="0.3"
    />
  </svg>
);

export default function Logo({
  animating = false,
  className = "h-64 w-64",
}: {
  className?: string;
  animating?: boolean;
}) {
  return (
    <div className={cn("p-[8%]", className)}>
      <div className="relative w-full h-full">
        <Sparkle
          className={cn(
            "absolute left-0 top-[15%] h-[70%] w-[70%]",
            animating &&
              "animate-jump-out animate-infinite animate-duration-[1500ms] animate-alternate-reverse"
          )}
        />
        <Sparkle
          className={cn(
            "absolute top-0 right-0 h-[50%] w-[50%]",
            animating &&
              "animate-jump-out animate-infinite animate-duration-[1500ms] animate-alternate-reverse animate-delay-[400ms]"
          )}
        />
        <Sparkle
          className={cn(
            "absolute bottom-[5%] right-[12%] h-[35%] w-[35%]",
            animating &&
              "animate-jump-out animate-infinite animate-duration-[1500ms] animate-alternate-reverse animate-delay-[900ms]"
          )}
        />
      </div>
    </div>
  );
}
