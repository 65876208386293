import { createContext, useEffect, useState, useContext } from "react";
import { User } from "@supabase/supabase-js";
import LoginDialog from "~/components/login-dialog";
import posthog from "posthog-js";
import supabase from "~/utils/supabase";
import { validLocationJson } from "~/utils/location";
import { PUBLIC_IPINFO_TOKEN } from "~/utils/constants";
import { track } from "~/utils/track";
import mixpanel from "mixpanel-browser";

type Profile = {
  name: string | null;
};

type IpInfoLocation = {
  city: string;
  loc: string;
};

const UserContext = createContext<{
  loading?: boolean;
  user?: User;
  profile?: Profile;
  loginCheck: (fn: () => void) => void;
  location: UserLocation | null;
  setLocation: (location: UserLocation | null) => void;
  locationLoading: boolean;
}>({
  user: undefined,
  profile: undefined,
  loading: true,
  loginCheck: () => {},
  location: null,
  setLocation: () => {},
  locationLoading: true,
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [loginVisible, setLoginVisible] = useState(false);
  const [onSuccessCallback, setOnSuccessCallback] = useState<
    (() => void) | undefined
  >();

  const [locationLoading, setLocationLoading] = useState(true);
  const [location, setLocation] = useState<UserLocation | null>(null);

  const loginCheck = (fn: () => void) => {
    if (!user) {
      posthog.capture("login_opened");
      track("login_opened");
      openLoginModal(fn);
      return;
    }
    fn();
  };

  const openLoginModal = (callback?: () => void) => {
    setLoginVisible(true);
    setOnSuccessCallback(() => callback);
  };

  useEffect(() => {
    const checkUser = async () => {
      try {
        setLoading(true);

        const { data, error } = await supabase.auth.getUser();

        if (error) {
          throw error;
        }

        if (data) {
          setUser(data.user);
        }
      } catch (error) {
        // Do nothing
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" && session?.user) {
        setUser(session?.user);
      }

      if (event === "SIGNED_OUT") {
        setUser(undefined);
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loadUserProfile = async () => {
      if (!user) {
        setProfile(undefined);
        if (process.env.NODE_ENV === "production") {
          posthog.reset();

          setTimeout(async () => {
            await mixpanel.reset();
          }, 3000);
        }
        return;
      }

      const { data, error } = await supabase
        .from("user_profiles")
        .select("*")
        .eq("id", user.id)
        .single();

      if (error) {
        console.error("Error loading profile", error);
        return;
      }

      if (data) {
        setProfile(data);
        if (process.env.NODE_ENV === "production") {
          posthog.identify(user.id, {
            name: data.name,
            phone: user.phone,
            email: user.email,
          });
          mixpanel.identify(user.id);
          mixpanel.people.set({
            $name: data.name,
            $phone: user.phone,
            $email: user.email,
          });
        }
      }
    };

    loadUserProfile();
  }, [user]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        setLocationLoading(true);
        const storedLocation = localStorage.getItem("userLocation");

        if (storedLocation && validLocationJson(storedLocation)) {
          setLocation(JSON.parse(storedLocation));
        } else {
          const response = await fetch(
            `https://ipinfo.io/json?token=${PUBLIC_IPINFO_TOKEN}`
          );

          const data: IpInfoLocation = await response.json();

          if (data) {
            const [lat, lng] = data.loc.split(",");
            const newLocation = {
              label: data.city,
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            };

            setLocation(newLocation);

            localStorage.setItem("userLocation", JSON.stringify(newLocation));
          }
        }
      } catch (e) {
        console.log("Failed to fetch location:", e);
      } finally {
        setLocationLoading(false);
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (location) {
      localStorage.setItem("userLocation", JSON.stringify(location));
      const storedRecentLocations = localStorage.getItem("recent_locations");
      const recentLocations = storedRecentLocations
        ? JSON.parse(storedRecentLocations)
        : [];

      if (
        !recentLocations.length ||
        (recentLocations[0].lat !== location.lat &&
          recentLocations[0].lng !== location.lng)
      ) {
        recentLocations.unshift(location);
      }
      if (recentLocations.length > 5) {
        recentLocations.pop();
      }

      localStorage.setItem("recent_locations", JSON.stringify(recentLocations));
    }
  }, [location]);

  return (
    <UserContext.Provider
      value={{
        user,
        profile,
        loading,
        loginCheck,
        location,
        setLocation,
        locationLoading,
      }}
    >
      <LoginDialog
        isOpen={loginVisible}
        onOpenChange={() => setLoginVisible(!loginVisible)}
        onSuccess={() => {
          if (onSuccessCallback) {
            onSuccessCallback();
            setOnSuccessCallback(undefined);
          }
        }}
      />
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);
