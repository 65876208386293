import mixpanel, { Callback, Dict, RequestOptions } from "mixpanel-browser";

export function track(
  event: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback
) {
  if (process.env.NODE_ENV === "production") {
    mixpanel.track(event, properties, optionsOrCallback, callback);
  }
}
